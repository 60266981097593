export const termsText = `These Terms and Conditions govern the use of Spangles InfoTech’s website, services, and all
associated content. By accessing or using our website or services, you agree to be bound by
these Terms and Conditions. If you disagree with any part of these terms, please discontinue
use of our services immediately. “We,” “Us,” or “Our” refers to Spangles InfoTech. “You”
“User” refers to the individual accessing or using our services or website. “Services” includes
all products, services, software, and content provided by Spangles InfoTech.`

export const terms = {
    "1": {
      "title": "Acceptance of Terms",
      "description": "By accessing or using our website or any of our services, you agree to these Terms and Conditions and consent to our privacy policy. You represent that you are at least 18 years of age or, if under 18, have the consent of a parent or legal guardian to use our services."
    },
  
    "2": {
      "title": "Use of Services",
      "Permitted Use": "You may use our website and services for lawful purposes only and in compliance with all applicable laws and regulations.",
      "Prohibited Actions": [
        "Upload or transmit any harmful code, viruses, or malware.",
        "Engage in any fraudulent, abusive, or illegal activity.",
        "Interfere with or disrupt the functioning of our services or the servers connected to our website.",
        "Impersonate any person or entity or falsely represent your affiliation with a person or entity."
      ]
    },
  
    "3": {
      "title": "User Accounts",
      "Account Registration": "Some of our services may require you to register for an account. You must provide accurate, complete, and up-to-date information.",
      "Account Security": "You are responsible for maintaining the confidentiality of your account and password. You agree to notify us immediately of any unauthorised access or use of your account.",
      "Termination": "We reserve the right to suspend or terminate accounts that are found in violation of these terms or for other reasons at our sole discretion."
    },
  
    "4": {
      "title": "Intellectual Property",
      "Ownership": "All content on our website, including text, images, graphics, logos, and software is the property of Spangles InfoTech or our licensors and is protected by copyright, trademark, and other intellectual property laws.",
      "Limited License": "We grant you a limited, non-exclusive, non-transferable, and revocable license to access and use our website and services solely for your personal, non-commercial use.",
      "Restrictions": "You may not copy, modify, distribute, sell, or lease any part of our content or services without our prior written consent."
    },
  
    "5": {
      "title": "Pricing and Payment",
      "Service Fees": "Certain services or products may have associated fees, which will be outlined in our service agreements or invoices. All prices are subject to change without notice.",
      "Payment Terms": "Payments must be made in accordance with the agreed terms. Failure to make timely payments may result in service suspension or termination.",
      "Refund Policy": "Our refund policy, if applicable, will be outlined in specific service agreements or as otherwise specified by us."
    },
  
    "6": {
      "title": "Third-Party Links and Services",
      "description": "Our website and services may contain links to third-party websites or services. Spangles InfoTech is not responsible for the content, privacy policies, or practices of these external sites. Access to any third-party sites is at your own risk, and we encourage you to review their terms and conditions."
    },
  
    "7": {
      "title": "Disclaimer of Warranties",
      "As Is Basis": "Our services are provided ‘as is’ and ‘as available,’ without warranties of any kind, either express or implied.",
      "No Guarantee of Results": "We do not guarantee any specific results from the use of our services. Your use of our services is at your own risk.",
      "No Warranty": "We disclaim all warranties, including but not limited to implied warranties of merchantability, fitness for a particular purpose, non-infringement, and any warranties arising from the course of dealing or usage of trade."
    },
  
    "8": {
      "title": "Limitation of Liability",
      "description": "To the fullest extent permitted by law, Spangles InfoTech and its affiliates, officers, employees, agents, and partners will not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, any loss of data, use, goodwill, or other intangible losses resulting from:",
      "Liabilities": [
        "Your use of or inability to use our services.",
        "Unauthorised access to or use of our servers and/or any personal information stored within.",
        "Any interruption or cessation of transmission to or from our services."
      ]
    },
  
    "9": {
      "title": "Indemnification",
      "description": "You agree to defend, indemnify, and hold harmless Spangles InfoTech and its affiliates, employees, contractors, agents, and partners from and against any claims, damages, obligations, losses, liabilities, costs, or debt and expenses arising from:",
      "Indemnity Reasons": [
        "Your use and access of our services.",
        "Your violation of any term of these Terms and Conditions.",
        "Your violation of any third-party rights, including any intellectual property, confidentiality, or privacy right."
      ]
    },
  
    "10": {
      "title": "Termination",
      "description": "Spangles InfoTech reserves the right to suspend or terminate your access to our website or services, with or without notice, for conduct that violates these terms, for any unlawful activity, or if we determine it is necessary for our operations or other users."
    },
  
    "11": {
      "title": "Governing Law",
      "description": "These Terms and Conditions are governed by and construed in accordance with the laws of the State of Tamil Nadu, India, without regard to its conflict of law principles. Any legal action or procedures arising from or relating to these Terms will be brought exclusively in the courts."
    },
  
    "12": {
      "title": "Changes to Terms and Conditions",
      "description": "Spangles InfoTech reserves the right to update or modify these Terms and Conditions at any time without prior notice. We will post the revised terms on our website, and the changes will take effect upon posting. Your continued use of our services constitutes acceptance of the modified terms."
    },
  
    "13": {
      "title": "Entire Agreement",
      "description": "These Terms and Conditions constitute the entire agreement between you and Spangles InfoTech regarding your use of our website and services. They supersede any prior understandings, agreements, or representations, whether written or oral, related to the subject matter hereof. Any additional or different terms proposed by you are expressly rejected unless agreed upon in writing by Spangles InfoTech."
    },
  
    "14": {
      "title": "Additional Assistance",
      "description": "If you do not understand any part of these Terms and Conditions or if you have questions, please feel free to contact us. Our Customer Service Department is available to assist you.",
      "Contact Info": [{
        "Email": "spanglesinfotech@gmail.com",
        "Phone": "+91 88707312114"
      }]
    },
  
    "15": {
      "title": "Copyright Notice",
      "description": "All website design, graphics, text selections, arrangements, and all software are the exclusive property of Spangles InfoTech or its licensors and are protected by copyright law.",
      "Copyright": "Copyright © 2024, Spangles InfoTech. ALL RIGHTS RESERVED."
    }
  }
  