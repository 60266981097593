export const firstText = `At Spangles Infotech, we are committed to protecting your privacy. This policy outlines how we collect, use, and safeguard your personal information when you interact with our website.`

export const policy = [
    {
        title:"Information We Collect",
        description:"We collect information to better serve our clients, improve our services, and enhance user experience. The information collected may include:",
        "Personal Information": [{
            "Contact Information": "Including, but not limited to, your name, email address and phone number, postal address, job title, and organisation.",
            "Account Information": "If you create an account with us, we may collect login credentials, preferences, and other details associated with your account.",
            "Communication Details": "Information you provide in communications with us, such as enquiries, feedback, or requests for support."
        }
        ],
        "Automatically Collected Data" : [{
            "Usage Data":"This includes information about your interaction with our website, such as pages visited, time spent on each page, links clicked, and other actions taken.",
            "Device Information":"Information about the device and browser you use to access our website, including IP address, operating system, browser type, and device identifiers.",
            "Location Information": `We may infer your approximate location based on your IP
            address to improve our service relevance.`
        }
        ],
        "Cookies and Tracking Technologies" :[
            `We use cookies, web beacons, and similar tracking technologies to collect information
            about your use of our site, personalise your experience, and provide analytical insights`,
            `You can control cookie preferences through your browser settings, but some
            functionality may be impacted if cookies are disabled.`
        ]
    },
    {
        title:"How We Use Your Information",
        description:`We use your information to provide, improve, and personalise our services, as well as to
        enhance our customer interactions. The primary ways we use your information include:`,
        "To Provide and Improve Our Services": `This includes responding to inquiries,
        processing service requests, managing customer accounts, and improving our offerings
        based on customer needs.`,
        "Communication": `We may use your contact information to send important notices,
        updates, promotional content, and other relevant information. You can opt out of
        marketing communications at any time.`,
        "Analysis and Research": `We analyse data on website usage and interactions to improve
        site functionality, service relevance, and user experience.`,
        "Legal Compliance and Security": `We may use or disclose information as required by
        law or to protect our rights, property, and the security of our users and services.`
    },
    {
        title:"Information Sharing and Disclosure",
        description:`We are committed to protecting your privacy and will not sell, rent, or lease your personal
        information to third parties. However, there are specific circumstances where we may share
        your information:`,
        "Service Providers and Business Partners": `We may share data with trusted partners
        who perform functions on our behalf, such as hosting, analytics, payment processing,
        or customer support. These parties are bound by confidentiality agreements.`,
        "Legal Requirements": `We may disclose information if required by law, such as
        responding to a subpoena or court order, or when we believe disclosure is necessary to
        protect our rights, your safety, or the safety of others.`,
        "Business Transfers": `In the event of a merger, acquisition, or sale of assets, we may
        transfer information as part of the business transaction, subject to confidentiality terms.`
    },
    {
        title:"Data Retention",
        description:`We retain personal information only as long as necessary for the purposes outlined in this
        policy. After this period, we securely delete or anonymize the data. The retention period may
        vary depending on the type of data and applicable legal or contractual requirements.`
    },
    {
        title:"Data Security",
        description:`We implement standard industry practices to protect your personal information from
        unauthorised access, disclosure, alteration, or destruction. These security measures include
        administrative, technical, and physical safeguards. However, no data transmission or storage
        system is guaranteed to be fully secure, and we cannot ensure absolute security.`
    },
    {
        title:"Your Rights and Choices",
        description:`Depending on your jurisdiction, you may have the following rights regarding your personal
        information:`,
        "Access and Correction": `You may request access to, correction of, or updates to your
        personal information.`,
        "Deletion": `You may request the deletion of your personal information, subject to certain
        legal limitations.`,
        "Restriction": `You may request that we restrict the processing of your personal
        information under certain circumstances.`,
        "Data Portability": `You may request to receive your personal information in a structured,
        commonly used, and machine-readable format.`,
        "Objection and Withdrawal of Consent": `You may object to processing or withdraw
        consent at any time for specific types of processing, such as marketing communications.`,
        "description":`To exercise your rights, please contact us using the information provided in Section 9.`
    },
    {
        title:"International Data Transfers",
        description:`If you are located outside India, please be aware that your information may be transferred to,
        stored, or processed in India or other jurisdictions. We take appropriate measures to ensure
        adequate protection of your information in compliance with applicable legal requirements.`
    },
    {
        title:"Changes to This Privacy Policy",
        description:`Spangles InfoTech may update this privacy policy periodically to reflect changes in our
        practices, technologies, legal requirements, or for other operational reasons. We encourage you
        to review this policy regularly to stay informed about how we protect your privacy. Significant
        Changes will be communicated via our website or direct communication channels.`
    },
    {
        title:"Contact Us",
        description:`If you have any questions, concerns, or requests related to this privacy policy or our data
        practices, please contact us at:`,
        "Spangles InfoTech":[{
            "Address": "389, Joe Daniel Street, Palpannai, Nagercoil",
            "Email": "spanglesinfotech@gmail.com",
            "Phone": "+91 8870731214"
        }]
    },
]