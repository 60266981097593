import React from 'react'
import "../../Style/Home.css";
import "../../Style/Animation.css";
import moonIcon from "../../image/moon.png"
import footerIcon from "../../image/home-footer.png"
import deerIcon from "../../image/deer.png"

export const ChristmasPage = () => {
  return (
    <>
        <img src={moonIcon} alt="" className="wave1 moon-icon"/>
        <img src={deerIcon} alt="" className="wave1 deer-icon" />
        <div className="tree-icon wave1" >
        <img src={footerIcon} alt="" className="wave1 tree" style={{ width:"100%", objectFit:"fill"}} />
        </div>
    </>
  )
}