import React from 'react'
import "../Style/works.css"

export const Works = ({isProduct}) => {
  const works = ["elyonwork", "saral", "chruchwork", "rc promoters", "bethwork", "oscar", "college", "mega scan", "dayspring", "kevin jose", "shree", "builders"]
  return (
   <>
<div style={{ marginTop: '10%', marginBottom: '10%' }} >
    <h1 className={!isProduct && `sv animatable moveUp`} style={{marginBottom:isProduct && "20px", color:isProduct && "#345261" , fontWeight:"bold"}}><b>OUR RECENT WORKS</b>   </h1>
    <h3
      className={!isProduct && "mt-5 mb-5 text111 animatable fadeIn"}
      style={{
        fontStyle: "normal",
        marginBottom:isProduct && "20px"
      }}
    >
      Proud projects that make us stand out
    </h3>
    <p
      style={{ fontSize: "20px", textAlign: 'center',color:"#2C2C2C", fontWeight:"500" }}
    >
      To achieve and maintain high standards we are committed to
      investing in the most talented IT support specialists in India,
      with the experience, creativity, and qualifications to exceed
      the highest expectations. We are known for our works and we hope
      you enjoy looking at some of our recent works.
    </p>
  </div>
  <div className="work-card-cont">
    {works.map((work) => (
      <div className='work-card' key={work}>
        <img src={require(`../image/${work}.png`)} alt="shree" className={`works-img ${work === "builders" ? "builder" :""}`}/>
      </div>
    ))}
  </div>
   </>
  )
}
