import React from "react";
import "../Style/policy.css";
import { firstText, policy } from "../utils/variable/privacy";
import { terms, termsText } from "../utils/variable/terms";
import Footer from "./Footer";

export const Policy = ({ title, isTerms }) => {

  const data = isTerms ? terms : policy ;
  const text = isTerms ? termsText : firstText
  const content= isTerms ? "Privacy Policy" : "Terms of Service"
  const date = "17/07/2023"

  const MappedSubValue = ({subValue})=>(
    subValue.map((item)=>(
      !Array.isArray(item) && typeof item === "object" ?
      Object.entries(item).map(([subKey, subValue])=>(
        <p className="mb-1" key={subKey} >
        <span style={{color:"black", fontWeight:"600", fontSize:"16px"}}>{subKey !== "description" && subKey !== "title" && subKey + " : "}</span>
        {subValue}
        </p>
      ))
      : <p className="mb-1">{item}</p>
    ))
  )
  return (
    <>
      <main className="policy-page">
          <div className="title-cont">
              <h1 className="m-0">{title}</h1>
          </div>
        <div className="d-flex flex-column  gap-2">
          <h6 className="m-0"><span className="fw-semibold" style={{fontSize:"18px"}}>Last Changes to {content}: </span>{date}</h6>
          <p>{text}</p>
          <div style={{fontSize:"16px"}}>
              {Object.entries(data)?.map(([key, value]) => (
              <React.Fragment key={key}>
                  <p
                  className="m-0"
                  style={{ color: "#345261", fontWeight: "600", fontSize: "24px"}}
                  >
                  {value.title}
                  </p>
                  {typeof value === "object" && !Array.isArray(value) ? (
                  Object.entries(value).map(([subKey, subValue]) => {
                    console.log("val", Array.isArray(subValue) )
                    return(
                      <p key={subKey}>
                      <span style={{color:"black", fontWeight:"600", fontSize:"18px",paddingBottom:"10px"}}>{subKey !== "description" && subKey !== "title" && subKey + " : "}</span>
                      {Array.isArray(subValue) ? <MappedSubValue subValue={subValue} /> :subKey !== "title" && subValue}
                      </p>
                  )})
                  ) : Array.isArray(value) ? (
                  value.map((val, index) => <p key={index} >{val}</p>)
                  ) : (
                  <p className="m-0" style={{paddingLeft:"10px"}}>{value}</p>
                  )}
              </React.Fragment>
              ))}
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};
