export const firstAbout = `
  Spangles Infotech, launched in 2016, has established itself as a trusted leader in the web and app
  development sector, helping hundreds of clients across diverse industries bring their ideas to life.
  Our mission is to meet the growing demand for exceptional web and mobile app development by
  building a reliable and skilled development team that collaborates with creative leaders to expand
  our perspective. We offer a wide range of expert solutions, including accounting software,
  management systems, and services tailored for churches, schools, universities, hotels, e-commerce
  sites, and web design. By delivering sincere and high-quality outputs, we have earned the trust of
  numerous clients and are committed to maintaining this trust through hard work and dedication.
  Our team members are our greatest asset, and we continuously invest in their growth by providing a
  supportive and innovative work environment. With a focus on strategic planning that combines
  marketing, technology, and creativity, we aim to drive business growth and conversions while acting
  in the best interests of our clients.
`

export const secondAbout = `We firmly believe that the success of our company lies in a strong organisational structure and a
motivated workforce. To achieve this, we actively recruit skilled professionals who share our vision
and are dedicated to realising our goals. Beyond offering competitive salaries and welfare packages
that exceed industry standards, we foster an environment of collaboration, innovation, and
continuous learning. This enables our team to enhance their skills, boost productivity, and stay
ahead in a rapidly changing industry. At Spangles Infotech, we are not just building software
solutions—we are shaping the future of digital transformation by empowering businesses with the
tools they need to succeed in a dynamic marketplace.`


export const vision = `Our mission is to establish ourselves as a premier software development and technology company
by delivering innovative and reliable solutions that align with industry standards and evolving client
needs. We are committed to providing exceptional services that exceed expectations while fostering
a culture of creativity and excellence. By creating a supportive and dynamic environment, we
empower our employees to thrive in the ever-changing field of information technology, ensuring
they contribute to delivering cutting-edge solutions that drive success for our clients and partners.`

export const mission = `Our vision is to be a leading software development and technology company, leveraging industry
standards and advanced technologies to provide exceptional business and technological solutions to
our clients. We strive to deliver unparalleled services and solutions while cultivating an innovative
and efficient work environment that empowers our team to excel in the dynamic field of information
technology. Achieving excellence for both our clients and employees remains at the core of our
goals.`