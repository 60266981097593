import React, { useEffect } from "react";
import snowflakeIcon from "../image/snowflake.png";
import "../Style/Animation.css";

export const SnowFall = () => {
  return (
    <div className="snowfall wave1">
      {Array(40)
        .fill(0)
        .map((_, index) => {
          const size = Math.floor(Math.random() * 60);
          return (
            <div
              key={index}
              className="snowflake wave1"
              style={{
                left: `${Math.floor(Math.random() * 100)}%`,
                animationDelay: `${(index + 1) * 0.8}s`,
                height: `${size}px`,
                width: `${size}px`,
              }}
            >
              <img src={snowflakeIcon} alt="flake" className="flake-icon" />
            </div>
          );
        })}
    </div>
  );
};
