import React, { useEffect, useRef, useState } from 'react';
import christmasAudio from "../../audio/christmas.mpeg";
import { RxSpeakerOff } from "react-icons/rx";
import { RxSpeakerModerate } from "react-icons/rx";
import "../../Style/Home.css";

export const BackgroundMusic = ({ waveAnimation }) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayAudio = () => {
    const audio = audioRef.current;
    if (audio && isPlaying) {
      audio.loop = true;
      audio.volume = 0.1;
      audio.play().then(() => {
        setIsPlaying(true);
      }).catch((error) => {
        console.error("Error playing audio:", error);
      });
    }else{
        audio.pause()
    }
  };

  const handleUserInteraction = () => {
      setIsPlaying(!isPlaying)
  };

  useEffect(() => {
      handlePlayAudio();
  }, [isPlaying ]);

  useEffect(()=>{
    if(!isPlaying){
        window.addEventListener('scroll', handleUserInteraction);
    }

    return () => {
      window.removeEventListener('scroll', handleUserInteraction);
    };
  },[handlePlayAudio])

  return (
    <div className='bg-music'>
      <audio ref={audioRef} src={christmasAudio} />
      <div onClick={handleUserInteraction} className='d-flex justify-content-center align-items-center' style={{width:"50px", height:"50px", borderRadius:"50%", border:"2px solid #345261"}} >
        {
            !isPlaying ?
             <RxSpeakerOff size={30} color='#345261'/>
            :<RxSpeakerModerate size={30} color='#345261' />
        }
      </div>
    </div>
  );
};
